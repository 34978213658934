import React from 'react';
import { SWRConfig } from 'swr';
import { ThemeProvider } from 'styled-components';
import { StylesProvider } from '@material-ui/styles';
import { MuiThemeProvider } from '@material-ui/core/styles';
import GlobalStyle from 'styles/styles';
import muiTheme from 'styles/mui-theme';
import extendealTheme from 'styles/extendeal-theme';
import { apiPharmaFetcher } from 'utils/axios';
import { AlertProvider } from './alertContext/alert-context';
import { AuthProvider } from './auth-context';
import { SnackProvider } from './snackbar-context';
import { PointOfSaleProvider } from './point-of-sale-context';
import { StatusProvider } from './control-state';
import { CloseModal } from './close-modal-context';
import { SkeletonLoaderModal } from './loading-skeleton';
import { ReloadProducts } from './reload_products';
import { CartProvider } from './cart-context';
import { SearchProductProvider } from './search-product-context';
import { FiltersProvider } from './orders/orders';
import { NewSearchProductProvider } from './openSearch/newSearchDataContext';
import { NewCartProvider } from './cart/new-cart-context';
import { AlertError } from './alert-error';
import { DragAndDropProvider } from './dragAndDropContext';
import { Snackbar } from './snackbar/snackbar-context';
import { CreateOrderProvider } from './createOrder/create-order-context';
import { ModalDontHaveCredentialsProvider } from './modals/modal-dont-have-credentials';
import { UrlProvider } from './url-context';
import { DrawerProvider } from './DrawerCredentialComparison/DrawerCredentialComparison';
import { CredentialErrorProvider } from './CredentialsErrors/CredentialsErrors';
import { ModalProvider } from './modalContext/modalContext';
import { ModalProviderCdO } from './modalCdO/modalCdO';
import { ModalProviderCdOAlert } from './alertModalCdo/alertModalCdo';
import { RecommendedProductsProvider } from './recommendedProducts/recommended-products-context';
import { ModalProviderWithoutStock } from './ModalWithoutStock/modalWithoutStock';
import { ModalWithoutCredit } from './ModalWithoutCredit/ModalWithoutCredit';
import { ModalProviderVolumeDiscount } from './ModalVolumeDiscount/modalVolumeDiscount';
import { AlertsProviders } from './Alerts/Alerts';
import { ProductUpdateQuantityProvider } from './update-quantity-product';
import { ListBoxDrugManufacturersProvider } from './ListBoxDrugManufacturers/ListBoxDrugManufacturers-context';
import { ToggleSwitchProvider } from './toggleSwitch/ToggleSwitchContext';

function AppProviders({ children }) {
    return (
        <MuiThemeProvider theme={muiTheme}>
            <GlobalStyle />
            <ThemeProvider theme={extendealTheme}>
                <SWRConfig
                    value={{
                        fetcher: apiPharmaFetcher,
                    }}
                >
                    <ToggleSwitchProvider>
                        <ProductUpdateQuantityProvider>
                            <AlertsProviders>
                                <ModalProviderVolumeDiscount>
                                    <ModalProviderWithoutStock>
                                        <ModalWithoutCredit>
                                            <ModalProviderCdOAlert>
                                                <ModalProviderCdO>
                                                    <ModalProvider>
                                                        <UrlProvider>
                                                            <FiltersProvider>
                                                                <StatusProvider>
                                                                    <AuthProvider>
                                                                        <PointOfSaleProvider>
                                                                            <CredentialErrorProvider>
                                                                                <DrawerProvider>
                                                                                    <ModalDontHaveCredentialsProvider>
                                                                                        <CreateOrderProvider>
                                                                                            <DragAndDropProvider>
                                                                                                <Snackbar>
                                                                                                    <AlertError>
                                                                                                        <CartProvider>
                                                                                                            <NewCartProvider>
                                                                                                                <SearchProductProvider>
                                                                                                                    <NewSearchProductProvider>
                                                                                                                        <RecommendedProductsProvider>
                                                                                                                            <SkeletonLoaderModal>
                                                                                                                                <ReloadProducts>
                                                                                                                                    <CloseModal>
                                                                                                                                        <SnackProvider>
                                                                                                                                            <AlertProvider>
                                                                                                                                                <ListBoxDrugManufacturersProvider>
                                                                                                                                                    {
                                                                                                                                                        children
                                                                                                                                                    }
                                                                                                                                                </ListBoxDrugManufacturersProvider>
                                                                                                                                            </AlertProvider>
                                                                                                                                        </SnackProvider>
                                                                                                                                    </CloseModal>
                                                                                                                                </ReloadProducts>
                                                                                                                            </SkeletonLoaderModal>
                                                                                                                        </RecommendedProductsProvider>
                                                                                                                    </NewSearchProductProvider>
                                                                                                                </SearchProductProvider>
                                                                                                            </NewCartProvider>
                                                                                                        </CartProvider>
                                                                                                    </AlertError>
                                                                                                </Snackbar>
                                                                                            </DragAndDropProvider>
                                                                                        </CreateOrderProvider>
                                                                                    </ModalDontHaveCredentialsProvider>
                                                                                </DrawerProvider>
                                                                            </CredentialErrorProvider>
                                                                        </PointOfSaleProvider>
                                                                    </AuthProvider>
                                                                </StatusProvider>
                                                            </FiltersProvider>
                                                        </UrlProvider>
                                                    </ModalProvider>
                                                </ModalProviderCdO>
                                            </ModalProviderCdOAlert>
                                        </ModalWithoutCredit>
                                    </ModalProviderWithoutStock>
                                </ModalProviderVolumeDiscount>
                            </AlertsProviders>
                        </ProductUpdateQuantityProvider>
                    </ToggleSwitchProvider>
                </SWRConfig>
            </ThemeProvider>
        </MuiThemeProvider>
    );
}

export * from './auth-context';
export * from './snackbar-context';
export * from './alertContext/alert-context';
export * from './point-of-sale-context';
export * from './cart-context';
export * from './cart/new-cart-context';
export * from './snackbar/snackbar-context';
export * from './createOrder/create-order-context';
export * from './modals/modal-dont-have-credentials';
export * from './openSearch/newSearchDataContext';
export * from './recommendedProducts/recommended-products-context';
export * from './ListBoxDrugManufacturers/ListBoxDrugManufacturers-context';
export * from './productComparison/scroll-footer';
export * from './productComparison/expanded-footer';
export * from './productComparison/modal-discounts';
export * from './productComparison/recommendations-context';
export * from './productComparison/containerRef-context';
export * from './productComparison/footer-context';
export * from './progressBarPrice/progressBarPrice-context';

export { AppProviders };
