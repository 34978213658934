import React, { useState, useEffect } from 'react';
import { useToaster } from 'rsuite';
import style from './Toast.module.css';
import { IToastProps } from './interfaces/Toast.interface';
import ToastWrapper from './ToastWrapper/ToastWrapper';
import ToastIcon from './ToastIcon/ToastIcon';
import ToastProgressBar from './ToastProgressBar/ToastProgressBar';
import { Close } from '@material-ui/icons';

const Toast = ({ message, duration = 5000, type = 'success', actionButton, showProgress = true, styleComponent } : IToastProps) => {
    const toaster = useToaster();
    const [timeLeft, setTimeLeft] = useState(duration);
    const [progress, setProgress] = useState(100);

    useEffect(() => {
        if (timeLeft === 0) {
            return;
        };
        const intervalId = setInterval(() => {
          setTimeLeft(prevTime => {
            const newTimeLeft = prevTime - 100;
            if (newTimeLeft <= 0) {
              clearInterval(intervalId);
              return 0;
            }
            return newTimeLeft;
          });
        }, 100);
    
        return () => clearInterval(intervalId);
    }, [timeLeft]);

    useEffect(() => {
        setProgress((timeLeft / duration) * 100);
        if (timeLeft <= 0) {
            toaster.clear();
        }
    }, [timeLeft, duration, toaster]);

    return (
        <ToastWrapper type={type} message={message}>
            <div className={style['content']}>
                <ToastIcon type={type} />
                <div className={style['text-container']}>
                    {message.title && <span style={styleComponent}  className={`${style['text-content']} ${style['title']}`}>
                        {message.title}
                    </span>}
                    <span className={`${style['text-content']} ${style['description']}`}>
                        {message.description}
                    </span>
                </div>
                {actionButton && <div className={style['action-container']}>
                    <button className={style['text-link']} onClick={actionButton.action}>{actionButton.label}</button>
                </div>}
            </div>
            <div className={style['close-container']}>
                <Close width={16} height={16} onClick={() => toaster.clear()} />
            </div>
            {showProgress && <ToastProgressBar progress={progress} type={type} />}
        </ToastWrapper>
    );
};

export default Toast;
