import { Form, InputGroup, FormControlProps } from 'rsuite';

import style from './FormInput.module.css';
import { EX6338 } from 'config/flags';

type Props = Omit<FormControlProps, 'name'> & {
    name?: string;
    type: string;
    label: string;
    error?: string | number | null;
    isRequired?: boolean;
    maxLength?: number;
    maxWidth?: boolean;
    value?: string;
    onEyeClick?: () => void;
    autoComplete?: string;
    showErrorIcon?: boolean;
    showOptionalLabel?: boolean;
    showStartIcon?: boolean;
    startIcon?: string | JSX.Element;
    customStyleStartIcon?: object;
    applyBorderErrorStyle?: boolean;
};

const FormInput = ({
    name,
    type,
    label,
    accepter,
    error,
    isRequired,
    maxLength,
    maxWidth,
    value,
    onEyeClick,
    autoComplete = 'on',
    showErrorIcon,
    showOptionalLabel,
    showStartIcon,
    startIcon,
    customStyleStartIcon,
    applyBorderErrorStyle,
    ...rest
}: Props) => {
    const EyeIcon = <img src="/assets/images/eye.svg" width={24} height={24} />;
    const EyeSlashIcon = <img src="/assets/images/eye-slash.svg" width={24} height={24} />;

    // cambios a partir de EX6338 rediseño login
    // errorApply => borde rojo input (primero chequeamos que applyErrorStyle exista)
    // icon => icono ojo password
    const errorApply = EX6338
        ? applyBorderErrorStyle !== null && applyBorderErrorStyle !== undefined
            ? applyBorderErrorStyle
            : error
        : error;
    
    const icon = EX6338 ? (type === 'password' ? EyeSlashIcon : EyeIcon) : type === 'password' ? EyeIcon : EyeSlashIcon;

    const passwordType = EX6338
        ? name === 'password' || name === 'newPassword' || name === 'repeatNewPassword'
        : name === 'password';

    return (
        <Form.Group className={style['form-input-group']} controlId={`form-input-${name}`}>
            <Form.ControlLabel>
                <span className={style['form-input-label']}>{label}</span>
                {isRequired && <span className={style['form-input-required']}>*</span>}
                {!isRequired && showOptionalLabel && <span className={style['form-input-optional']}>(opcional)</span>}
            </Form.ControlLabel>
            <InputGroup
                style={{ width: maxWidth ? '100%' : 'unset' }}
                className={errorApply ? style['form-input-error'] : style['form-input-container']}
            >
                {showStartIcon && <InputGroup.Addon style={{ ...customStyleStartIcon }}>{startIcon}</InputGroup.Addon>}
                <Form.Control
                    name={name}
                    type={type}
                    accepter={accepter}
                    errorMessage={null}
                    className={style['form-input']}
                    autoComplete={autoComplete}
                    value={value}
                    {...rest}
                />
                {passwordType && (
                    <InputGroup.Addon className={style['form-eye-icon']} onClick={() => onEyeClick?.()}>
                        {icon}
                    </InputGroup.Addon>
                )}
            </InputGroup>
            <div className={style['message-container']}>
                <span className={style['form-error-message']}>
                    {showErrorIcon && <img src="/assets/images/error.svg" alt="icon error" width={16} height={16} />}
                    {error}
                </span>
                {maxLength && (
                    <span>
                        {value?.length}/{maxLength}
                    </span>
                )}
            </div>
        </Form.Group>
    );
};

export default FormInput;
